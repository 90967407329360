'use client';
import { useState, useEffect } from 'react';
import { SignIn } from '@clerk/nextjs';
import { Typography } from '@howl/shared-ui-kit/v3/typography';
import Link from 'next/link';
import { HowlLogoVideoBackground } from './HowlLogoVideoBackground';

export const SignInComponent = ({ redirectUrl }: { redirectUrl?: string }) => {
  const [isCardLoaded, setIsCardLoaded] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (document.querySelector('.cl-card')) {
        setIsCardLoaded(true);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <main className={'flex justify-center items-center h-full w-full'}>
      <HowlLogoVideoBackground />
      <div
        className={
          'mx-auto flex z-20 flex-col justify-center w-fit h-fit rounded-3xl shadow-[0px_8px_16px_0px_rgba(0,0,0,0.10)]'
        }
      >
        <div className={'z-10'}>
          <SignIn
            appearance={{
              elements: {
                logoBox: 'hidden',
                card: '!rounded-none !shadow-none md:px-8 px-4',
                cardBox:
                  '!bg-white !rounded-t-3xl !border-b-none sm:w-[400px] !shadow-none',
                headerTitle: 'text-headline-large text-3xl text-primary',
                headerSubtitle: 'text-headline-large text-text-tertiary',
                formButtonPrimary:
                  '!bg-interaction-primary h-14 text-sm normal-case',
                socialButtons: 'mx-auto gap-4 flex flex-row',
                providerIcon: 'h-8 w-8',
                button__facebook:
                  'h-16 w-16 rounded-medium !shadow-md !border border-dark-opacity-10',
                button__google:
                  'h-16 w-16 rounded-medium !shadow-md !border border-dark-opacity-10',
                button__tiktok:
                  'h-16 w-16 rounded-medium !shadow-md !border border-dark-opacity-10',
                dividerText: 'text-base text-tertiary',
                dividerRow: 'uppercase font-mono !text-primary !my-0',
                buttonArrowIcon: 'hidden',
                formFieldInput: 'h-12 py-4 !border-primary',
                rootBox:
                  'w-full h-full flex flex-col items-center justify-center z-20 !my-0 !shadow-none',
                footer: 'hidden',
                footerAction: '!py-4',
                footerActionText: 'text-sm font-sans font-medium',
                footerActionLink: 'text-sm underline underline-offset-4',
              },
            }}
            forceRedirectUrl={redirectUrl}
            signUpForceRedirectUrl={redirectUrl}
            signUpUrl={`/sign-up?redirect_url=${
              redirectUrl ? encodeURIComponent(redirectUrl) : ''
            }`}
          />
        </div>

        {isCardLoaded && (
          <div
            className={
              'rounded-b-3xl bg-white flex flex-col gap-2 p-1 sm:w-[400px] -mt-6 z-20 justify-center'
            }
          >
            <div
              className={'flex flex-col justify-center items-center px-4 pb-2'}
            >
              <Typography variant={'caption'} className={'text-tertiary'}>
                Howl now uses password-less sign in for added security.
              </Typography>{' '}
              <Link
                href={
                  'https://help.howl.link/hc/en-us/articles/26445756641179-Logging-Into-Howl-Link'
                }
                target="_blank"
              >
                <Typography
                  variant={'caption'}
                  className={'underline text-tertiary'}
                >
                  Learn more.
                </Typography>
              </Link>
            </div>

            <div
              className={
                'p-4 bg-dark-opacity-50 rounded-3xl flex flex-row gap-1 justify-center items-center'
              }
            >
              <Typography variant={'body'} className={'text-secondary text-sm'}>
                New to Howl?
              </Typography>
              <Link href={'/sign-up'}>
                <Typography
                  variant={'body'}
                  className={'text-link underline underline-offset-4 text-sm'}
                >
                  Sign up.
                </Typography>
              </Link>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};
